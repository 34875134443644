import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout/layoutWithContainer.js";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { Styled } from "theme-ui";
import SEO from "../components/seo";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Services" mdxType="SEO" />
    <h1 {...{
      "id": "services"
    }}>{`Services`}</h1>
    <p>{`Our Enterprise Cloud Services provides a variety of innovative and comprehensive IT Cloud solutions.
We deliver quality services in the most efficient way, and our experts will work collaboratively with you to
customize our offerings to your particular needs.​`}</p>
    {
      /* ## Table Of Contents  ## */
    }
    <h2 {...{
      "id": "we-cover"
    }}>{`We Cover`}</h2>
    <hr />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "940px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "30.319148936170215%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/svg+xml,%3csvg%20xmlns=\\'http://www.w3.org/2000/svg\\'%20width=\\'400\\'%20height=\\'121\\'%3e%3cpath%20d=\\'M247%2053v8h10V45h-10v8m-28%2012v9h5c4%200%204%200%203%202-1%201%200%201%205%201s6%200%205-1c0-2%201-2%204-2h4V56h-26v9m-73-6c-9%202-16%209-18%2017-1%203-1%203%201%201h10c2-1%203-1%205%201h10c1%201%202%200%204-1h2l-1%202-1%205-1%205c-2%200-2%202-2%209%200%209%200%209%203%209h2V89l1-4c0-8%205-9%206-2%201%209%203%208%203%200l-1-7%203%202c3%202%203-1%200-7-5-9-16-15-26-12m74%206v7h24V58h-24v7m27%204v8h7V63l-4-1h-3v7m11%203v5h8c9%200%209%200%208-6v-5h-16v6m1-1l1%205h6c7%200%207%200%206-7%200-2%200-2-7-2h-6v4M47%2077l-4%201h-3v7l-1%207-1%205v5h5l16%201h12V84h-5c-7-1-10%200-9%202l-1%204v2l-2-2-1-7-1-5-1-1h-2l-1-1-1%201m200%2011v8h9V79h-9v9m11%200v10h11l1-4V80l-6-1h-6v9m1%200v7h9V80h-9v8m-44-1v9c0%208%200%208-2%208l-2%201%2018%201%2018-1-2-1c-2%200-2%200-2-9v-8l-14-1-14%201m2%208v7h24V88h-24v7M24%2091v10c0%202%200%202%206%202h6v-6l1-6h-4l-3-1c0-2-5-1-6%201\\'%20fill=\\'%23d3d3d3\\'%20fill-rule=\\'evenodd\\'/%3e%3c/svg%3e')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Services We Cover",
          "title": "Services We Cover",
          "src": "/static/287f7578bde38f47099e6f4dec776a7c/18ec8/service-range.png",
          "srcSet": ["/static/287f7578bde38f47099e6f4dec776a7c/0fa9c/service-range.png 310w", "/static/287f7578bde38f47099e6f4dec776a7c/a05ab/service-range.png 620w", "/static/287f7578bde38f47099e6f4dec776a7c/18ec8/service-range.png 940w"],
          "sizes": "(max-width: 940px) 100vw, 940px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Click on one of the links below for more details:`}</p>
    <div className="checkmarks">
      <ul>
        <li parentName="ul"><Styled.a as={AniLink} paintDrip to="/services/consulting">Cloud Consulting Services</Styled.a></li>
        <li parentName="ul"><Styled.a as={AniLink} paintDrip to="/services/solutions">Cloud Solutions</Styled.a></li>
        <li parentName="ul"><Styled.a as={AniLink} paintDrip to="/services/migrations">Cloud Migrations</Styled.a></li>
        <li parentName="ul"><Styled.a as={AniLink} paintDrip to="/services/devops">DevOps</Styled.a></li>
        <li parentName="ul"><Styled.a as={AniLink} paintDrip to="/services/security-audit">Cyber Security Audit</Styled.a></li>
        <li parentName="ul"><Styled.a as={AniLink} paintDrip to="/services/prisma-cloud">Prisma Cloud</Styled.a></li>
      </ul>
    </div>
    <h2 {...{
      "id": "engagement-model"
    }}>{`Engagement Model`}</h2>
    <hr />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "922px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "47.39696312364425%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/svg+xml,%3csvg%20xmlns=\\'http://www.w3.org/2000/svg\\'%20width=\\'400\\'%20height=\\'190\\'%3e%3cpath%20d=\\'M364%2074c-3%202-3%207%200%2010%202%202%207%203%209%201l2%201c2%201%202%201%204-1%202-3%203-6%201-6l-5-3c-4-4-7-5-11-2M21%2085c-3%201-7%207-7%2010%200%2011%2012%2016%2020%208%209-8-1-23-13-18m61%205c-6%207%202%2016%2010%2012%202-2%202-2%204%200s4%201%206-4c1-4%201-4-3-5l-6-3c-3-3-7-3-11%200m145%200c-5%206%201%2015%209%2012h5c1%201%202%201%204-1%203-4%203-8%200-8a562%20562%200%2001-7-3c-3-3-7-3-11%200m67%200c-3%204-3%207%201%2010%203%203%204%204%209%202h3c1%204%207-1%207-6%200-3-1-4-6-3l-1-1c1-1-4-4-7-4l-6%202m71-1h6v12h-5l-6%201%201%201%201%2011v45l26%201a716%20716%200%2000-1-61c0-1%200-2-2-2-4-1-6%200-6%202s-1%202-3%202h-4V89h6l-6-1-7%201\\'%20fill=\\'%23d3d3d3\\'%20fill-rule=\\'evenodd\\'/%3e%3c/svg%3e')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Engagement Model",
          "title": "Engagement Model",
          "src": "/static/2534fa2171d7a62f73ea7459af6cfef0/0f41c/engagement-model.png",
          "srcSet": ["/static/2534fa2171d7a62f73ea7459af6cfef0/0fa9c/engagement-model.png 310w", "/static/2534fa2171d7a62f73ea7459af6cfef0/a05ab/engagement-model.png 620w", "/static/2534fa2171d7a62f73ea7459af6cfef0/0f41c/engagement-model.png 922w"],
          "sizes": "(max-width: 922px) 100vw, 922px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <h2 {...{
      "id": "benefits-of-hatton"
    }}>{`Benefits of Hatton`}</h2>
    <hr />
    <h3 {...{
      "id": "reduce-operational-expenses"
    }}>{`Reduce operational expenses`}</h3>
    <div className="checkmarks">
      <ul>
        <li parentName="ul">{`Minimize your infrastructure, footprint through consolidation.`}</li>
      </ul>
    </div>
    <h3 {...{
      "id": "gain-efficiencies"
    }}>{`Gain efficiencies`}</h3>
    <div className="checkmarks">
      <ul>
        <li parentName="ul">{`Reduce application redundancies and minimize serve management.`}</li>
      </ul>
    </div>
    <h3 {...{
      "id": "streamline-business-processes"
    }}>{`Streamline business processes`}</h3>
    <div className="checkmarks">
      <ul>
        <li parentName="ul">{`Integrate heterogeneous infrastructure.`}</li>
      </ul>
    </div>
    <br /><br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      